/* global uuidv4, Component, _ */
class itemsSectionComponent extends Component {

    static name() {
        return "itemsSectionComponent";
    }

    static componentName() {
        return "itemsSectionComponent";
    }

    getData() {
        return function () {
            return {
                viewmode: this.$store.state.defaultItemSize || 'module',
                itemsFlag:false,
                orderSelected:"",
                filteredItems: [],
                appState:null,
                firstrun: false,
                promiseStack: [],
                callbackPromise: null,
                trackHash: null
            };
        };
    }

    getWatch() {
        return {
            "$route.params.category": function (to, from){
                this.addFilterCategory();
            },
            "$store.state.itemTimeFlag": function (newValue, oldValue){
                this.filteredItems = this.$store.getters.getItemsVisibles;
                if(this.filteredItems.length && newValue != oldValue) {
                    //console.log( newValue, oldValue);
                    const currency = this.$store.state.priceDeal.priceDeal ? 
                        this.$store.state.priceDeal.priceDeal.fields.Currency :
                        this.$store.state.setting.Currency;
                    this.gtmTrackEcommerceItemList(this.filteredItems.slice(this.initItem,this.lastItem), currency, window.document.title || this.$route.fullPath || this.$route.name || "Item List by Ecommerce", newValue);
                }
            },
            'readyLoad':function (newValue, oldValue){
                if(newValue != oldValue && !this.firstrun) {
                    this.addFilterCategory();
                    this.reorderItems();
                }
            },
        };
    }

    mounted() {
        return async function () {
            const IsItABackButton = window.popStateDetected;
            window.popStateDetected = false;
            if(IsItABackButton)
                this.$store.dispatch('updateCategory', null);
            this.viewmode = this.$store.state.defaultItemSize || 'module';
            if(this.readyLoad) {
                this.addFilterCategory();
            }
        };
    }

    getComputed() {
        return {
            readyLoad: function (){
                return this.$store.state.itemsFullLoader && this.$store.state.appState == 'ready';
            },
            viewId: function (){
                return "unique__" + this.filteredItems.length + "__" + this.$store.state.itemTimeFlag;
            },
            initItem:function (){
                return this.$store.getters.getItemPerPage * this.$store.getters.getOffset;
            },
            lastItem:function (){
                return this.$store.getters.getItemPerPage * this.$store.getters.getOffset + this.$store.getters.getItemPerPage;
            },
            moduleObject: function () {
                return {'green-text': this.viewmode == 'module'};
            },
            listObject: function () {
                return {'green-text': this.viewmode == 'list'};
            },
            isLoading(){
                if(!this.$store.state.itemsFullLoader)
                    return true;
                if(this.$store.getters.getAppState == "loading")
                    return true;
                if(this.$store.getters.getAppState == "ready")
                    return false;
                if(this.$store.getters.getAppState == "fast-ready")
                    return false;
                return true;
            },
            itemClassObject: function () {
                return {
                    'oo-item-container-list':this.viewmode == 'list',
                    'oo-item-container':this.viewmode == 'module' || this.viewmode == 'module-small',
                    'oo-item-container-small': this.viewmode == 'module-small',
                    'oo-item-container-normal': this.viewmode == 'module',
                    'col-12': this.viewmode == 'list',
                    'col-6 col-sm-6 col-lg-3 px-1 px-lg-2': this.viewmode == 'module',
                    'col-6 col-sm-4 col-lg-2 px-1 px-lg-2': this.viewmode == 'module-small'
                };
            },
            categoryImage: function (){
                const getCategoryImage = (catPath,level) =>{
                    if(level === -1)
                        return null;
                    let catObject = this.$store.state.cat_by_name.get(catPath[level].name);
                    if(!catObject)
                        return null;
                    if(!catObject.showAttachedImage)
                        return null;
                    if(catObject.images && catObject.images.length > 0) {
                        const imageData = [];
                        for(let image of catObject.images)
                            imageData.push({
                                fields: {
                                    ItemPhotoUri: image,
                                    OnlyMobile: false
                                }
                            });
                        return imageData;
                    }
                    return getCategoryImage(catPath,level - 1);
                };
    
                if(this.$route.query.category && this.$store.state.cat_history){
                    return getCategoryImage(this.$store.state.cat_history, this.$store.state.cat_history.length - 1);
                }
                return null;
            },
            sliderConfig: function (){
                return {OnlyMobile: false};
            }
        };
    }

    getMethods() {
        return {
            addFilterCategory:this.addFilterCategory,
            fetchData:_.throttle(this.fetchData,1500,{'trailing': true}),
            loadItems: this.loadItems,
            searchCategory:this.searchCategory,
            toogleFavorite: this.toogleFavorite,
            reorderItems: this.reorderItems,
            createFilterCategory: this.createFilterCategory,
            resetCurrentFilters:this.resetCurrentFilters,
            updateContext:this.updateContext
        };
    }

    createFilterCategory(urlCategory, global = true) {
        if(!urlCategory)
            return new Promise((resolve,reject)=>{resolve(true);});
        let categoryName = urlCategory.split("__"); //.replace("%2F", "/")
        let catData;
        if(categoryName.length == 1)
            catData = this.$store.getters.getCategoryDataByName(categoryName[0]);
        else
            catData = this.$store.getters.getCategoryDataByCode(categoryName[0]);
        if(catData){
            window.document.title = this.capitalize(catData.name);
            if(this.$store.state.cat_selected && catData.code == this.$store.state.cat_selected.code)
                return new Promise((resolve,reject)=>{ this.fetchData(); resolve(true);});
            let filterType = global ? 'globalClassification' : "classificationFilter";
            let filterName =  filterType + '_' + catData.code;
            let objectFilter = {};
            objectFilter.type = filterType;
            objectFilter.name = filterName;
            objectFilter.condition = `(x.ClassificationPath['${catData.code}']!=undefined || (x.Classification.split(',').indexOf('${catData.code}') != -1))`;
            objectFilter.label = catData.code;
            objectFilter.code = catData.code;
            objectFilter.filterField = "Classification";
            objectFilter.result = true;
            objectFilter.hiddens = new Map();
            if (global) {
                return new Promise((resolve,reject)=>{
                    this.$store.dispatch('addGlobalFilter', objectFilter).then((res)=>{
                        this.fetchData();
                        resolve(true);
                    });
                });
            }
            else{
                objectFilter.name = `classification-filter-${catData.name}-${catData.code}`;
                objectFilter.value = catData.name;
                objectFilter.active = true;
                objectFilter.itemCount = 0;
                return new Promise((resolve,reject)=>{
                    this.$store.dispatch('addFilter', objectFilter).then((res)=>{
                        this.fetchData();
                        resolve(true);
                    });
                });
            }
        }
        return new Promise((resolve,reject)=>{ this.fetchData(); resolve(true);});
    }

    resetCurrentFilters(){
        return new Promise((resolve,reject)=>{
            this.$store.dispatch('restoreFilter')
                .then(() => { 
                    if(this.$store.state.filtersObjects && this.$store.state.filtersObjects.size > 0){
                        for(let filterKey of this.$store.state.filtersObjects.keys()){
                            let filter = this.$store.state.filtersObjects.get(filterKey);
                            if(filter.type != "globalClassification") {
                                this.$store.dispatch('addFilter', filter);
                            }
                        }
                    }
                });
        }); 

    }

    addFilterCategory(){
        this.updateContext();
        if(this.$route.params.category && this.$route.query.category && this.$route.params.category.split("__")[0] != this.$route.query.category.split("__")[0] && this.$store.state.itemsFullLoader) {
            this.promiseStack.push(this.createFilterCategory(this.$route.params.category,true));
            this.callbackPromise = ()=>{
                return new Promise((resolve,reject)=>{
                    setTimeout(() => {
                        this.createFilterCategory(this.$route.query.category, false).then((res)=>{
                            this.resetCurrentFilters();
                            resolve(true);
                        });
                    },500);
                });
            };
        }
        else {
            let categoryCode = this.$route.params.category;
            if (!categoryCode)
                categoryCode = this.$route.query.category;
            if (this.$store.state.itemsFullLoader) {
                this.createFilterCategory(categoryCode, true).then((res) => {
                    if (this.$store.state.screenState)
                        this.$store.dispatch('toogleLockScreen');
                });
            } else {
                this.promiseStack.push(this.createFilterCategory(categoryCode,true));
            }
        }

        if(this.$route.query.search && this.$store.state.filtersObjects.size == 0 && this.$store.state.itemsFullLoader) {
            let filter = {};
            let searchWords = this.$route.query.search.toString();
            let synonym = this.$store.getters.trSynonyms(searchWords);
            filter.name = 'inputSearch-' + uuidv4();
            filter.type = `inputSearch`;
            filter.filterValue = searchWords;
            let settings = this.$store.getters.getSetting;
            filter.condition = "";
            if(settings.SearchFields && settings.SearchFields.split(",").length > 0){
                let filterFields = settings.SearchFields.split(",");
                let isFirts = true;
                let itemTemplate = this.$store.state.items_store.values().next().value;
                for(let filterField of filterFields){
                    if(itemTemplate.hasOwnProperty(filterField)) {
                        //Se puede mejorar para evalua según tipo de valor.
                        if (!isFirts) {
                            filter.condition += " || ";
                        }
                        filter.condition += ` ( x.${filterField} && ( score(x.${filterField}.toLowerCase(),'${searchWords}')>=0.30 `;
                        if (synonym) {
                            filter.condition += ` || (x.${filterField}.toLowerCase(),'${synonym}')>=0.30 ) `;
                        }
                        filter.condition += ` )) `;
                        let filterFormat = filterField + "_format";
                        if(itemTemplate.hasOwnProperty(filterFormat)) {
                            filter.condition += `  || ( x.${filterFormat} && ( score(x.${filterFormat}.toLowerCase(),'${searchWords}')>=0.30 `;
                            if (synonym) {
                                filter.condition += ` || (x.${filterFormat}.toLowerCase(),'${synonym}')>=0.30 )  `;
                            }
                            filter.condition += ` )) `;
                        }
                        isFirts = false;
                    }
                }
            }
            else {
                if (synonym)
                    filter.condition = `score(x.Name.toLowerCase(),'${searchWords}')>=0.30 || x.Code=='${searchWords}' || score(x.Name.toLowerCase(),'${synonym}')>=0.30`;
                else
                    filter.condition = `score(x.Name.toLowerCase(),'${searchWords}')>=0.30`;
            }
            filter.synonym = synonym;
            filter.result = true;
            this.promiseStack.push(this.$store.dispatch('addGlobalFilter',filter));
        }
    }

    fetchData() {
        this.itemFlag = !this.itemFlag;
        this.firstrun = true;
        this.itemFlag = !this.itemFlag;
        let newitems = this.$store.getters.getItemsVisibles;
        let itemWithPrices = [];
        for(let item of newitems) {
            let storeItem = this.$store.getters.getItemByCode(item.Code);
            if (!storeItem.isCalculate)
                itemWithPrices.push(storeItem);
        }
        if(itemWithPrices.length > 0) {
            this.promiseStack.push(this.$store.commit('updateAppState',"load items"));
            this.promiseStack.push(this.$store.dispatch('updatePrices', itemWithPrices));
        }

        Promise.all(this.promiseStack).then((values)=>{
            if(this.callbackPromise)
                this.callbackPromise().then(()=>{
                    this.promiseStack = [];
                    this.callbackPromise = null;
                    this.filteredItems = this.$store.getters.getItemsVisibles;
                });
            else{
                this.promiseStack = [];
                this.callbackPromise = null;
                this.filteredItems = this.$store.getters.getItemsVisibles;
            }
        });
    }

    updateContext() {
        let newitems = this.$store.getters.getItemsVisibles;
        let itemWithPrices = [];
        for(let item of newitems) {
            let storeItem = this.$store.getters.getItemByCode(item.Code);
            if (!storeItem.isCalculate)
                itemWithPrices.push(storeItem);
        }
        if(itemWithPrices.length > 0) {
            this.$store.dispatch('updatePrices', itemWithPrices);
        }
    }

    loadItems(category = null, offset = 0, items_per_page = 25) {
        this.$store.dispatch('loadItems', category, offset, items_per_page);
    }

    searchCategory(category) {
        function searchTree(element, matchingCode) {
            if (element.code == matchingCode) {
                return element;
            } else if (element && element.children != null) {
                let result = null;
                for (let i = 0; result == null && i < element.children.length; i++) {
                    result = searchTree(element.children[i], matchingCode);
                }
                return result;
            }
            return null;
        }

        if (category) {
            this.loadItems(category.code);
            let cattree = this.$store.getters.getCategories;
            let node = searchTree(cattree[0], category.code);
            this.$store.dispatch('updateCategory', node);
        } else
            this.loadItems(null);
    }

    toogleFavorite(item) {
        /*if ($('#favorite-item-' + item.internalId).prop('textContent') != 'favorite')
            $('#favorite-item-' + item.internalId).prop('textContent', 'favorite');
        else
            $('#favorite-item-' + item.internalId).prop('textContent', 'favorite_border');*/

        this.$store.dispatch('addItemToFavorite', item);
    }

    reorderItems() {
        let settingItemOrdered = this.$store.getters.getSettingItemOrdered.length ? this.$store.getters.getSettingItemOrdered[0].fields.internalId : null;
        if(settingItemOrdered) {
            this.$store.dispatch('reorderItems',settingItemOrdered);
            this.$emit('reorder');
        }
        //this.fetchData(); No es necesario
    }

    getTemplate() {
        return `<div class="row items-view-options" >
                        <div class="category-banner w-100" v-if="categoryImage">
                            <sliderSectionComponent :items="categoryImage" :config="sliderConfig" />
                            <!--<img :src="categoryImage" class="img-fluid">-->
                        </div>
                    <itemsBreakcrumb></itemsBreakcrumb>
                    <div class="col-12">
                        <div class="row justify-content-between align-items-center items-view-options mb-2 mb-md-1">
                            <div class="col-6 col-sm-7 px-2 mb-2 mb-sm-0">
                                <orderItemsSelect @reorder="reorderItems"/>
                            </div>
                            <div class="col-6 col-sm-5 px-2">
                                <div class="row justify-content-end view-mode pr-2">
                                    <div class="mr-2 d-none d-sm-block">
                                        <span @click="viewmode='module-small'" :class="{'active':viewmode=='module-small'}">
                                            <i class="fas fa-th fa-2x"></i>
                                        </span>
                                    </div>
                                    <div class="mr-2">
                                        <span @click="viewmode='module'" :class="{'active':viewmode=='module'}">
                                            <i class="fas fa-th-large fa-2x"></i>
                                        </span>
                                    </div>
        
                                    <div class="mr-2">
                                        <span @click="viewmode='list'" :class="{'active':viewmode=='list'}">
                                            <i class="fas fa-th-list fa-2x"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pagination-top d-none d-md-block" v-if="filteredItems.length>0">
                        <div class="row justify-content-center">
                            <paginationComponent :items="filteredItems"></paginationComponent>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <template v-if="isLoading || !readyLoad">
                                  <div class="loading-container">
                                      <div class="loading">
                                          <i class="fas fa-spinner fa-spin fa-3x"></i>
                                      </div>
                                  </div>
                            </template>
                            <template v-else>
                                <template v-if="filteredItems.filter(x=>x.visible).length>0">
                                    <template v-for="item in filteredItems.slice(initItem,lastItem)">
                                        <template v-if="item.visible">
                                            <div :class="itemClassObject" >
                                              <template v-if="viewmode=='list'">
                                                  <itemViewListComponent :item="item.fields? item.fields : item" ></itemViewListComponent>
                                              </template>
                                              <template v-else>
                                                  <itemViewModuleComponent :item="item.fields? item.fields : item" ></itemViewModuleComponent>
                                              </template>
                                            </div>
                                        </template>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="container text-center no-items">
                                        <h4>{{tr('Not items for current filters, choose a category')}}</h4>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </div>
                    <div class="col-12">
                        <template v-if="filteredItems.length>0">
                            <paginationComponent :items="filteredItems"></paginationComponent>
                        </template>
                    </div>
                </div>`;
    }
}

itemsSectionComponent.registerComponent();